<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('fundRecord.default[2]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div class="tool">
        <table style="text-align: center;width: 95%">
          <tr><td> USDT{{ $t("hall.default[1]") }}</td></tr>
          <tr style="font-size: 18px"><td>
            {{ Number(UserInfo.balance).toFixed(2) }}
          </td></tr>
          <tr><td>
            ≈
          </td></tr>
          <tr style="font-size: 18px"><td>
            {{Number(UserInfo.balance*InitData.usdtinfo.price).toFixed(2) }} {{InitData.usdtinfo.sign}}
          </td></tr>
        </table>
      </div>
      <van-tabs
        :ellipsis="false"
        :border="false"
        line-width="60"
        v-model="tabsIndex"
        @change="changeTabs"
      >
        <van-tab
          v-for="item in taskTabs"
          :key="item.state"
          style="padding: 0 12px"
        >
          <template #title>
            <div style="margin-top: -20px">
                {{ item.text }}
            </div>
          </template>

          <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
            <van-list
              v-model="isLoad"
              :finished="isFinished"
              :finished-text="
                listData[tabsIndex].length ? $t('vanPull[0]') : $t('vanPull[1]')
              "
              @load="onLoad"
              :class="{ Empty: !listData[tabsIndex].length }"
            >
              <div v-if="tabsIndex == 0" style="margin-top: 60px">
                <van-cell
                  class="FundItem"
                  v-for="(item, index) in listData[tabsIndex]"
                  :key="item.dan"
                  :to="`/user/recharge/${item.dan}`"
                >
                  <div :class="`icon tag${tabsIndex}`" slot="icon"></div>
                  <template #title>
                    <div>
                      <span style="color: #FFF">{{ item.dan }}</span>
                      <span>{{ item.adddate }}</span>
                    </div>
                    <div>
                      <span class="money">+{{ Number(item.money) }}</span>
                      <span>{{ item.status_desc }}</span>
                    </div>
                  </template>
                </van-cell>
              </div>
              <div v-else style="margin-top: 60px">
                <van-cell
                  class="FundItem"
                  v-for="(item, index) in listData[tabsIndex]"
                  :key="item.order_id"
                >
                  <div :class="`icon tag${tabsIndex}`" slot="icon"></div>
                  <template #title>
                    <div>
                      <span class="dan" style="color:#cccccc">{{ item.trade_number }}</span>
                      <span>{{ item.trade_time }}</span>
                    </div>
                    <div>
                      <span class="money"
                        >{{ item.jj }}{{ item.trade_amount }}</span
                      >
                      <span>{{
                        /[^\u4e00-\u9fa5]/g.test($t("fundRecord.default[2]"))
                          ? item.remarks
                          : item.trade_dec
                      }}</span>
                    </div>
                  </template>
                </van-cell>
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "FundRecord",
  components: {},
  props: ["fundState"],
  data() {
    return {
      listData: "",
      isLoad: false,
      isFinished: false,
      isRefresh: false,
      pageNo: 1,
      tabsState: 4,
      tabsIndex: 0,
      taskTabs: [
        { state: 4, text: this.$t("fundRecord.tabs[0]") },
        { state: 3, text: this.$t("fundRecord.tabs[1]") },
      ],
    };
  },
  computed: {},
  watch: {
    $route() {
      if (this.fundState) {
        this.tabsIndex = this.taskTabs.findIndex(
          (item) => item.state == this.fundState
        );
        this.tabsState = this.fundState;
      } else {
        this.tabsState = 4;
        this.tabsIndex = 0;
      }
      this.getListData("init");
    },
  },
  created() {

    this.listData = this.taskTabs.map((item) => "");
    if (this.fundState) {
      this.tabsIndex = this.taskTabs.findIndex(
        (item) => item.state == this.fundState
      );
      this.tabsState = this.fundState;
    }
    this.$parent.navBarTitle = "";

    this.getListData("init");
    console.log(this.listData);
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    onLoad() {
      this.getListData("load");
    },
    changeTabs(index) {
      this.tabsState = this.taskTabs[index].state;
      this.getListData("init");
    },
    getListData(type) {
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }
      if (this.tabsIndex == 0) {
        this.$Model.GetRechargeRecord(
          { state: 0, page_no: this.pageNo },
          (data) => {
            this.isLoad = false;
            if (data.code == 1) {
              if (type == "load") {
                this.listData[this.tabsIndex] = this.listData[
                  this.tabsIndex
                ].concat(data.info);
              } else {
                this.listData[this.tabsIndex] = data.info;
              }
              if (this.pageNo == data.data_total_page) {
                this.isFinished = true;
              } else {
                this.isFinished = false;
              }
            } else {
              this.listData[this.tabsIndex] = "";
              this.isFinished = true;
            }
          }
        );
      } else {
        this.$Model.FundDetails(
          { trade_type: this.tabsState, page_no: this.pageNo },
          (data) => {
            this.isLoad = false;
            if (data.code == 1) {
              if (type == "load") {
                this.listData[this.tabsIndex] = this.listData[
                  this.tabsIndex
                ].concat(data.list);
              } else {
                this.listData[this.tabsIndex] = data.list;
              }
              if (this.pageNo == data.data_total_page) {
                this.isFinished = true;
              } else {
                this.isFinished = false;
              }
            } else {
              this.listData[this.tabsIndex] = "";
              this.isFinished = true;
            }
          }
        );
      }
    },
    onRefresh() {
      this.getListData("init");
    },
  },
};
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#B5B5B5;
  }

  .van-pull-refresh {
    min-height: calc(100vh - 90px);
  }
  .PageBox>>>.tool::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -80%;
  }
  .tool {
    width: 95%;
    border-radius: 10px;
    background-color: #0076FA;
    color: #fff;
    height: 120px;
    overflow: hidden;
    z-index: 99;
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    padding: 20px;
  }
  .van-cell__title {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }

  .van-cell__title div {
    display: flex;
    flex-direction: column;
  }
  .van-cell__title div:nth-child(2) {
    align-items: flex-end;
  }
  .PageBox >>> .van-tabs__nav {
    position: absolute;
    height: 80px;
    background-color: #191C23;
    margin-top: 10px;
    width: 90%;
    left: 10px;
  }

  .PageBox >>> .van-tab--active {
    /*background-color: #F5C73A;*/
  }

  .FundItem{
    background-color: #191C23;
    margin-top: 5px;
    color:#aaaaaa;
  }

  .van-tab__pane {
    width: 98%;
    margin-left: 5px;
  }

  .PageBox >>> .van-tabs__line {
    height: 0px;
  }

  .PageBox /deep/ .van-tab__text {
    width: 90px;
    color: #aaaaaa;
    text-align: center;
    font-size: 18px;
  }
  .PageBox /deep/ .van-tabs__wrap {
    border-radius: 10px;
    height: 60px;
  }

  .PageBox /deep/ .van-tab :nth-of-type(1) div {
    background-color: #000;
    padding: 5px;
    border: 1px solid;
    border-radius: 20px;
    color: #fff;
  }

  .PageBox /deep/ .van-tab--active :nth-of-type(1) div {
    background-color: #0076FA;
    padding: 5px;
    border-radius: 20px;
    color: #fff;
  }
  .PageBox /deep/ .van-tab--active :nth-of-type(1) div div {
    background-color: #0076FA;
    padding: 5px;
    border-radius: 20px;
    color: #fff;
  }
  .myhead {
    position: fixed;
    top: 0px;
    font-size: 18px;
    color: #000;
    background: #fff;
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
</style>